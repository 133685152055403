<template>
  <v-data-table :headers="headers" :items="desserts" sort-by="no" dense>
    <template v-slot:top>
      <v-toolbar flat>
        <v-btn color="success" dark rounded :to="{ name: 'Create Tax' }">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ desserts.map(x => x.description).indexOf(item.description) + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    dialogDelete: false,
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Tax Type",
        value: "type",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Percentage",
        value: "percentage",
        sortable: false,
        divider: true,
        align: "end",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Sales Tax Account",
        value: "salesTaxAccount",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Purchase Tax Account",
        value: "purchaseTaxAccount",
        sortable: false,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.desserts = [
        {
          description: "Pajak Pertambahan Nilai",
          type: "Value Added Tax",
          percentage: 10,
          salesTaxAccount: "PPn Keluaran",
          purchaseTaxAccount: "PPn Masukan",
        },
        {
          description: "Jasa Software Komputer",
          type: "Income Tax Article 23",
          percentage: 2,
          salesTaxAccount: "PPh Pasal 23",
          purchaseTaxAccount: "Hutang Pajak PPh Ps23",
        },
        {
          description: "Jasa Teknik",
          type: "Income Tax Article 23",
          percentage: 2,
          salesTaxAccount: "PPh Pasal 23",
          purchaseTaxAccount: "Hutang Pajak PPh Ps23",
        },
        {
          description: "Jasa Kebersihan",
          type: "Income Tax Article 23",
          percentage: 2,
          salesTaxAccount: "PPh Pasal 23",
          purchaseTaxAccount: "Hutang Pajak PPh Ps23",
        },
        {
          description: "Jasa Freight Forwarding",
          type: "Income Tax Article 21",
          percentage: 2,
          salesTaxAccount: "PPh Pasal 23",
          purchaseTaxAccount: "Hutang Pajak PPh Ps23",
        },
        {
          description: "PPh 21",
          type: "Income Tax Article 23",
          percentage: 0,
          salesTaxAccount: "",
          purchaseTaxAccount: "Hutang Pajak PPh Ps21",
        },
      ];
    },
  },
};
</script>

<style></style>
